export enum TemplateWordingIdentifiers {
    ServingColumnHeaderEu = 1,
    StaticColumnHeaderEu = 2,
    ServingColumnHeaderEuOld = 3,
    StaticColumnHeaderEuOld = 4,
    PerHeaderCa = 5,
    ServingHeaderCa = 6,
    DailyValueCa = 7,
    AdditionalNutrientsInformation = 8,
    DailyValuePercentageInformation = 9,
    ServingColumnHeaderMx = 10,
    StaticColumnHeaderMx = 11,
    PerHeaderTw = 12,
    ServingSizeUS = 13,
    ServingsPerContainerUS = 14,
    AmountPerServingUS = 15,
    FluidOunceAlcoholContentUS = 16,
    ServingSizeKr = 17,
    DailyValueKr = 18,
    DailyValuePercentageInformationKr = 19,
    ItemsCn = 20,
    PerServingCn = 21,
    NRVPercentageCn = 22,
    MilliLitersCn = 23,
    KiloJoulesCn = 24,
    GramsCn = 25,
    MilliGramsCn = 26,
    ContainerSizeUS = 27,
    PerHeaderKr = 28,
    DailyValueBr = 29,
    DailyValuePercentageInformationBr = 30,
    ServingSizeAuNz = 31,
    ContainerSizeAuNz = 32,
    QuantityPerServingAuNz = 33,
    QuantityPerAuNz = 34,
    ServingHeaderHK = 35,
    PerHeaderHK = 36,
    MilliLitersHK = 37,
    GramsHK = 38,
    MilliGramsHK = 39,
    KiloJoulesHK = 40,
    KiloCaloriesHK = 41,
    ContainerSizeTw = 42,
    ServingsPerContainerTw = 43,
    ContainerSizeBr = 44,
    ServingsPerContainerBr = 45,
    ContainerSizeHK = 46,
    ServingsPerContainerHK = 47,
    ServingsPerContainerAuNz = 48,
    ServingsCountBr = 49,
    ServingSizeBr = 50,
    ServingSizeHK = 51,
    ServingsCountAuNz = 52,
    ServingsCountHK = 53,
    LitersHK = 54,
    MilliLitersTw = 55,
    KiloCaloriesTw = 56,
    GramsTw = 57,
    MilliGramsTw = 58,
    ServingColumnHeaderCl = 59,
    StaticColumnHeaderCl = 60,
}
