import { INutritionProperty, NutritionProperties } from "@/models";

export const NutritionConstants = {
    get addedSugars(): INutritionProperty {
        return {
            code: "AddedSugars",
            nutritionPropertyId: NutritionProperties.AddedSugars,
        };
    },
    get alcoholProperty(): INutritionProperty {
        return {
            code: "Alcohol",
            nutritionPropertyId: NutritionProperties.Alcohol,
        };
    },
    get carbohydratesProperty(): INutritionProperty {
        return {
            code: "Carbohydrates",
            nutritionPropertyId: NutritionProperties.Carbohydrates,
        };
    },
    get carbohydratesSugarsProperty(): INutritionProperty {
        return {
            code: "CarbohydratesSugars",
            nutritionPropertyId: NutritionProperties.CarbohydratesSugars,
        };
    },
    get cholesterol(): INutritionProperty {
        return {
            code: "Cholesterol",
            nutritionPropertyId: NutritionProperties.Cholesterol,
        };
    },
    get dietaryFibers(): INutritionProperty {
        return {
            code: "DietaryFibers",
            nutritionPropertyId: NutritionProperties.DietaryFibers,
        };
    },
    get energyKCalProperty(): INutritionProperty {
        return {
            code: "EnergyKCal",
            nutritionPropertyId: NutritionProperties.EnergyKCal,
        };
    },
    get energyKJProperty(): INutritionProperty {
        return {
            code: "EnergyKJ",
            nutritionPropertyId: NutritionProperties.EnergyKJ,
        };
    },
    get fatProperty(): INutritionProperty {
        return {
            code: "Fat",
            nutritionPropertyId: NutritionProperties.Fat,
        };
    },
    get nutritionProperties(): INutritionProperty[] {
        return [
            this.energyKCalProperty,
            this.energyKJProperty,
            this.fatProperty,
            this.saturatedFatProperty,
            this.carbohydratesProperty,
            this.carbohydratesSugarsProperty,
            this.proteinProperty,
            this.saltProperty,
            this.alcoholProperty,
            this.sodiumMgProperty,
            this.transFatProperty,
        ];
    },
    get proteinProperty(): INutritionProperty {
        return {
            code: "Protein",
            nutritionPropertyId: NutritionProperties.Protein,
        };
    },
    get saltProperty(): INutritionProperty {
        return {
            code: "Salt",
            nutritionPropertyId: NutritionProperties.Salt,
        };
    },
    get saturatedFatProperty(): INutritionProperty {
        return {
            code: "SaturatedFat",
            nutritionPropertyId: NutritionProperties.SaturatedFat,
        };
    },
    get sodiumMgProperty(): INutritionProperty {
        return {
            code: "SodiumMg",
            nutritionPropertyId: NutritionProperties.SodiumMg,
        };
    },
    get transFatProperty(): INutritionProperty {
        return {
            code: "TransFat",
            nutritionPropertyId: NutritionProperties.TransFat,
        };
    },
};
