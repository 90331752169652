import { User } from "@/models";
import { GetterTree } from "vuex";
import { IAccountState } from "./state";

const getters: GetterTree<IAccountState, any> = {
    isAuthenticated: (state): boolean => !!state.msalAccount,
    isUserActive: (state): boolean => state.user?.isActive ?? false,
    user: (state): User | null => state.user,
    userEmail: (state): string => (state.msalAccount?.idTokenClaims as any).email ?? "",
    userName: (state): string => (state.msalAccount?.idTokenClaims as any).name ?? "",
    userPhoto: (state): string => state.userPhoto,
    userUniqueIdentifier: (state): string => state.msalAccount?.localAccountId ?? "",
};

export { getters };
