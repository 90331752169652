export const ValidationRules = {
    get commonRules(): ICommonRules {
        return {
            urlMaximumLength: 2048,
        };
    },
    get countryRules(): ICountryRules {
        return {
            ageGateRestrictionMaximumLength: 100,
            ageGateRestrictionMinimumLength: 1,
            alcoholLabelMaximumLength:300,
            countryLabelMaximumLength: 300,
            displayBlockLabelMaximumLength: 300,
            displayMentionMaximumLength: 300,
            globalMessageMaximumLength: 3000,
            nutritionLabelMaximumLength: 300,
            pernodRicardWebsiteLabelMaximumLength: 300,
            pernodRicardWebsiteLinkMaximumLength: 300,
            portionPrefixMaximumLength: 300,
            portionSuffixMaximumLength: 300,
            productLabelMaximumLength:300,
            responsibleDrinkingWebsiteLabelMaximumLength: 300,
            responsibleDrinkingWebsiteLinkMaximumLength: 300,
            standardDrinkLabelMaximumLength: 300,
            standardDrinkUnitMaximumLength: 300,
            translatedWordingMaximumLength: 300,
        };
    },
    get languageRules(): ILanguageRules {
        return {
            languageLabelMaximumLength: 300,
        };
    },
    get userRules(): IUserRules {
        return {
            nameMaximumLength: 300,
        };
    },
};

export interface ICommonRules {
    urlMaximumLength: number;
}

export interface ICountryRules {
    ageGateRestrictionMaximumLength: number;
    ageGateRestrictionMinimumLength: number;
    alcoholLabelMaximumLength: number;
    countryLabelMaximumLength: number;
    displayBlockLabelMaximumLength: number;
    displayMentionMaximumLength: number;
    globalMessageMaximumLength: number;
    nutritionLabelMaximumLength: number;
    pernodRicardWebsiteLabelMaximumLength: number;
    pernodRicardWebsiteLinkMaximumLength: number;
    responsibleDrinkingWebsiteLabelMaximumLength: number;
    responsibleDrinkingWebsiteLinkMaximumLength: number;
    portionPrefixMaximumLength: number;
    portionSuffixMaximumLength: number;
    productLabelMaximumLength: number;
    standardDrinkLabelMaximumLength: number;
    standardDrinkUnitMaximumLength: number;
    translatedWordingMaximumLength: number;
}

export interface ILanguageRules {
    languageLabelMaximumLength: number;
}
export interface IUserRules {
    nameMaximumLength: number;
}
