import { format, formatISO } from "date-fns";

export class DatePickerWrap {
    // Properties

    hideDateMenu: boolean;

    private mDate: string;

    // Constructor

    constructor(date: string) {
        this.mDate = date;
        this.hideDateMenu = false;
    }

    // Getters & Setters

    get date(): string {
        return this.mDate ? format(new Date(this.mDate), "yyyy-MM-dd") : "";
    }

    set date(date: string) {
        this.mDate = date ? formatISO(new Date(date), { representation: "date" }) : "";
    }

    // Methods

    toApiDate(): string {
        return this.mDate ? formatISO(new Date(this.mDate), { representation: "date" }) : "";
    }
}
