import { msalInstance, scopes } from "@/services";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, CancelToken } from "axios";

const requestInterceptor = async (request: AxiosRequestConfig) => {
    const accessToken = await msalInstance.getAccessToken(scopes);

    // Add Authorization token
    if (accessToken != null) {
        request.headers.Authorization = `Bearer ${accessToken}`;
    }

    return Promise.resolve(request);
};

const errorResponseInterceptor = async (error: AxiosError) => {
    // If the user is unauthenticated, retry login
    if (error.response?.status === 401) {
        await msalInstance.getAccessToken(scopes);
    }

    return Promise.reject(error);
};

const createGeneratedClientsAxiosInstance = (allowTransformation = true): AxiosInstance => {
    const axiosInstance = axios.create({
        transformResponse: allowTransformation ? undefined : [(data) => data],
    });
    axiosInstance.interceptors.request.use(requestInterceptor);
    axiosInstance.interceptors.response.use(undefined, errorResponseInterceptor);
    return axiosInstance;
};

const downloadFileFromUrl = async (url: string, cancelToken?: CancelToken | undefined): Promise<Blob> => {
    const options: AxiosRequestConfig = {
        cancelToken,
        headers: {
            "Accept": "application/octet-stream",
        },
        method: "GET",
        responseType: "blob",
        url,
    };
    const axiosResponse = await downloadAxiosInstance.request<Blob>(options);
    return axiosResponse.data;
};

const createCancelToken = () => (axios.CancelToken.source());

const isCancelError = (value: any) => (axios.isCancel(value));

const generatedClientsAxiosInstance: AxiosInstance = createGeneratedClientsAxiosInstance(false);

const downloadAxiosInstance: AxiosInstance = axios.create();

export { generatedClientsAxiosInstance, createCancelToken, isCancelError, downloadFileFromUrl };

