export const DomPurifyConstants = {
    get globalMessageConfiguration(): IDomPurifyConfiguration {
        return {
            allowedAttributes: ["href", "rel", "target"],
            allowedTags: ["#text", "a", "br", "em", "li", "ol", "p", "", "strong", "u", "ul"],
            keepContent: false,
        };
    },

    get templateWordingConfiguration(): IDomPurifyConfiguration {
        return {
            allowedAttributes: ["href", "rel", "target"],
            allowedTags: ["#text", "a", "br", "em", "li", "ol", "p", "strong", "u", "ul"],
            keepContent: false,
        };
    },
};

export interface IDomPurifyConfiguration {
    allowedAttributes: string[] | undefined;
    allowedTags: string[] | undefined;
    keepContent: boolean | undefined;
}
