import { Roles } from "@/models";
import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const adminRoutes: RouteConfig[] = [
    {
        component: () => import(/* webpackChunkName: "admin-entities" */ "../views/admin/entities/AdminEntities.vue"),
        meta: {
            requiresMinimumRole: Roles.GlobalAdministrator,
        },
        name: RouteNames.affiliatesAdministration,
        path: "/admin/affiliates",
    },
    {
        component: () => import(/* webpackChunkName: "admin-entities" */ "../views/admin/entities/AdminEntities.vue"),
        meta: {
            requiresMinimumRole: Roles.Administrator,
        },
        name: RouteNames.brandsAdministration,
        path: "/admin/brands",
    },
    {
        component: () => import(/* webpackChunkName: "admin-locations" */ "../views/admin/locations/AdminLocations.vue"),
        meta: {
            requiresMinimumRole: Roles.GlobalAdministrator,
        },
        name: RouteNames.countriesAdministration,
        path: "/admin/countries",
    },
    {
        component: () => import(/* webpackChunkName: "admin-locations" */ "../views/admin/locations/id/AdminLocation.vue"),
        meta: {
            requiresMinimumRole: Roles.GlobalAdministrator,
        },
        name: RouteNames.countryAdministration,
        path: "/admin/countries/:id",
    },
    {
        component: () => import(/* webpackChunkName: "admin-locations" */ "../views/admin/generic/GenericCountry.vue"),
        meta: {
            requiresMinimumRole: Roles.GlobalAdministrator,
        },
        name: RouteNames.genericCountryAdministration,
        path: "/admin/genericCountry",
    },
    {
        component: () => import(/* webpackChunkName: "admin-languages" */ "../views/admin/languages/AdminLanguages.vue"),
        meta: {
            requiresMinimumRole: Roles.GlobalAdministrator,
        },
        name: RouteNames.languagesAdministration,
        path: "/admin/languages",
    },
    {
        component: () => import(/* webpackChunkName: "admin-publication" */ "../views/admin/publication/AdminPublication.vue"),
        meta: {
            requiresMinimumRole: Roles.GlobalAdministrator,
        },
        name: RouteNames.publicationAdministration,
        path: "/admin/publication",
    },
    {
        component: () => import(/* webpackChunkName: "admin-locations" */ "../views/admin/locations/AdminLocations.vue"),
        meta: {
            requiresMinimumRole: Roles.GlobalAdministrator,
        },
        name: RouteNames.regionsAdministration,
        path: "/admin/regions",
    },
    {
        component: () => import(/* webpackChunkName: "admin-locations" */ "../views/admin/locations/id/AdminLocation.vue"),
        meta: {
            requiresMinimumRole: Roles.GlobalAdministrator,
        },
        name: RouteNames.regionAdministration,
        path: "/admin/regions/:id",
    },
    {
        component: () => import(/* webpackChunkName: "admin-users" */ "../views/admin/users/AdminUsers.vue"),
        meta: {
            requiresMinimumRole: Roles.Administrator,
        },
        name: RouteNames.usersAdministration,
        path: "/admin/users",
    },
];

