import { AffiliateGet, BrandGet, CountryGet, LanguageGet } from "@/repositories";

interface IAppState {
    affiliates: AffiliateGet[];
    affiliatesPromise: Promise<void> | null;
    brands: BrandGet[];
    brandsPromise: Promise<void> | null;
    countries: CountryGet[];
    countriesPromise: Promise<void> | null;
    languages: LanguageGet[];
    languagesPromise: Promise<void> | null;
    regions: CountryGet[];
    regionsPromise: Promise<void> | null;
    showSidebar: boolean;
    title: string;
}

const initialState: IAppState = {
    affiliates: [],
    affiliatesPromise: null,
    brands: [],
    brandsPromise: null,
    countries: [],
    countriesPromise: null,
    languages: [],
    languagesPromise: null,
    regions: [],
    regionsPromise: null,
    showSidebar: false,
    title: "",
};

export { IAppState, initialState };

