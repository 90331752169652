import { WaitKeys } from "@/helpers";
import { User } from "@/models";
import { userRepository } from "@/repositories";
import { msalInstance } from "@/services";
import { ActionTree } from "vuex";
import { LOAD_ACCOUNT, LOAD_USER, LOGOUT, SET_PHOTO, SET_USER_PROMISE } from "./mutations-types";
import { IAccountState } from "./state";

export const actions: ActionTree<IAccountState, any> = {
    loadAccount: (context): void => {
        const account = msalInstance.getActiveAccount();
        context.commit(LOAD_ACCOUNT, account);
    },

    loadUser: async (context): Promise<void> => {
        // If there was an API call, wait for the result
        if (context.state.userPromise !== null) {
            return context.state.userPromise;
        }

        context.dispatch("wait/start", WaitKeys.userData, { root: true });
        try {
            const userDtoPromise = userRepository.getMe();
            context.commit(SET_USER_PROMISE, userDtoPromise);
            const userDto = await userDtoPromise;
            const user = new User(userDto);
            context.commit(LOAD_USER, user);
        }
        finally {
            context.commit(SET_USER_PROMISE, null);
            context.dispatch("wait/end", WaitKeys.userData, { root: true });
        }
    },

    loadUserPhoto: async (context): Promise<void> => {
        const uniqueId = context.state.msalAccount?.localAccountId ?? "";
        if (uniqueId !== "") {
            try {
                const photo = await userRepository.getGraphUserPhotoByUniqueId(uniqueId);
                context.commit(SET_PHOTO, photo);
                return;
            }
            catch {
                // User photo is not important so if it fails, do nothing
            }
        }
        context.commit(SET_PHOTO, "");
    },

    logout: async (context): Promise<void> => {
        msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        context.commit(LOGOUT);
    },
};
