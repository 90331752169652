import Vue from "vue";
import Vuex from "vuex";
import { account } from "./account";
import { IAccountState } from "./account/state";
import { app } from "./app";
import { IAppState } from "./app/state";

Vue.use(Vuex);

export interface IState {
    account: IAccountState;
    app: IAppState;
}

export const store = new Vuex.Store<IState>({
    modules: {
        account,
        app,
    },
});
