import { WaitKeys } from "@/helpers";
import { RouteNames } from "@/router";
import { msalInstance } from "@/services";
import { store } from "@/store";
import Vue from "vue";
import { NavigationGuard, NavigationGuardNext } from "vue-router";

const failed = (next: NavigationGuardNext<Vue>, errorCode: string) => {
    store.dispatch("wait/end", WaitKeys.userLogin);
    return next({ name: RouteNames.unauthorized, params: { errorCode } });
};

export const authGuard: NavigationGuard = async (to, from, next) => {
    Vue.nextTick(async () => {
        store.dispatch("wait/start", WaitKeys.userLogin);

        // Login with Azure AD
        const loggedIn = await msalInstance.login();
        if (!loggedIn) {
            return failed(next, "We cannot login you from your Microsoft account.");
        }

        // Load Microsoft User Account
        await store.dispatch("account/loadAccount");
        if (!store.getters["account/isAuthenticated"]) {
            return failed(next, "You do not have an account.");
        }

        store.dispatch("wait/end", WaitKeys.userLogin);

        return next();
    });
};
