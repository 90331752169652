import { IOrganizationLeaf } from "@/models/utilities";
import { AffiliateGet, BrandGet } from "@/repositories";

export const constructBrandLeaf = (brand: BrandGet, prefix = ""): IOrganizationLeaf => (
    {
        children: [],
        id: `${prefix}${brand.brandId}`,
        name: brand.name,
    });

export const constructAffiliateLeaf = (affiliate: AffiliateGet, brands: BrandGet[], prefix = "A", filterChildren = false, childrenPrefix = ""): IOrganizationLeaf => {
    const affiliateBrands = filterChildren
        ? brands.filter(brand => brand.affiliateId === affiliate.affiliateId)
        : brands;
    return {
        children: affiliateBrands.map(brand => constructBrandLeaf(brand, childrenPrefix)),
        id: `${prefix}${affiliate.affiliateId}`,
        name: affiliate.name,
    };
};

export const constructAffiliateLeafs = (affiliates: AffiliateGet[], brands: BrandGet[], prefix = "A", childrenPrefix = ""): IOrganizationLeaf[] => {
    const brandsWithAffiliate: BrandGet[] = [];
    const brandsWithoutAffiliate: BrandGet[] = [];

    for (const brand of brands) {
        if (brand.affiliateId === null) {
            brandsWithoutAffiliate.push(brand);
        }
        else {
            brandsWithAffiliate.push(brand);
        }
    }

    const organizationLeafs = affiliates.map(organization => constructAffiliateLeaf(organization, brandsWithAffiliate, prefix, true, childrenPrefix));
    organizationLeafs.push(...brandsWithoutAffiliate
        .map(brand => constructBrandLeaf(brand, childrenPrefix)));

    return organizationLeafs;
};
