import { AffiliateGet, BrandGet, CountryGet, LanguageGet } from "@/repositories";
import { GetterTree } from "vuex";
import { IAppState } from "./state";

const getters: GetterTree<IAppState, any> = {
    affiliates: (state): AffiliateGet[] => state.affiliates,
    brands: (state): BrandGet[] => state.brands,
    countries: (state): CountryGet[] => state.countries,
    languages: (state): LanguageGet[] => state.languages,
    regions: (state): CountryGet[] => state.regions,
    showSidebar: (state): boolean => state.showSidebar,
    title: (state): string => state.title,
};

export { getters };
