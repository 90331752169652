export class WaitKeys {
    public static readonly baseData = "global.baseData";

    public static readonly organizationsData = "global.organizations.*";

    public static readonly affiliatesData = "global.organizations.affiliates";

    public static readonly brandsData = "global.organizations.brands";

    public static readonly locationsData = "global.locations.*";

    public static readonly countriesData = "global.locations.countries";

    public static readonly languagesData = "global.locations.languages";

    public static readonly regionsData = "global.locations.regions";

    public static readonly userLogin = "global.user.login";

    public static readonly userData = "global.user.data";
}
