import { CountryCompleteGet, CountryGet, CountryLanguageDto, LanguageGet } from "@/repositories";

const getDefaultLanguage = (country: CountryGet | CountryCompleteGet | null): CountryLanguageDto | null => country?.languages.find(language => language.isDefault) ?? null;

const getCountryLabel = (country: CountryGet | CountryCompleteGet | null, englishLanguageId: number, defaultLabel = "Unknown name"): string => {
    if (country == null) {
        return defaultLabel;
    }
    if (englishLanguageId > 0) {
        const englishTranslationLabel = country.languages.find(language => language.languageId === englishLanguageId)?.countryLabel ?? "";
        if (englishTranslationLabel !== "") {
            return englishTranslationLabel;
        }
    }
    const defaultLanguage = getDefaultLanguage(country);
    return defaultLanguage !== null
        ? defaultLanguage.countryLabel
        : defaultLabel;
};

const getLanguageLabel = (language: LanguageGet | null, englishLanguageId: number, defaultLabel = "Unknown name"): string => {
    if (language == null) {
        return defaultLabel;
    }
    if (englishLanguageId > 0) {
        const englishTranslationLabel = language.languageLabels.find(languageLabel => languageLabel.translatedLanguageId === englishLanguageId)?.label ?? "";
        if (englishTranslationLabel !== "") {
            return englishTranslationLabel;
        }
    }
    return language.languageLabels
        .find(languagLabel => languagLabel.translatedLanguageId === language.languageId)?.label
        ?? defaultLabel;
};

const getParentCountry = (region: CountryGet | CountryCompleteGet | null, countries: CountryGet[] | null): CountryGet => {
    if (region?.parentId == null || countries == null) {
        return null;
    }
    return countries.find(country => country.countryId === region.parentId) ?? null;
};

export { getCountryLabel, getDefaultLanguage, getLanguageLabel, getParentCountry };

