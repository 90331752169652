const LOAD_ACCOUNT = "LOAD_ACCOUNT";
const LOAD_USER = "LOAD_USER";
const LOGOUT = "LOGOUT";
const SET_PHOTO = "SET_PHOTO";
const SET_USER_PROMISE = "SET_USER_PROMISE";

export {
    LOAD_ACCOUNT,
    LOAD_USER,
    LOGOUT,
    SET_PHOTO,
    SET_USER_PROMISE,
};
