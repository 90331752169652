import { Roles, User } from "@/models";
import { RouteNames } from "@/router";
import { store } from "@/store";
import { NavigationGuard } from "vue-router";

export const roleGuard: NavigationGuard = async (to, from, next) => {
    const minimumExpectedRole: Roles | null = to?.meta?.requiresMinimumRole;

    if (minimumExpectedRole == null) {
        return next();
    }

    const isUserActive: boolean = store.getters["account/isUserActive"];

    if (!isUserActive) {
        await store.dispatch("account/loadUser");
    }

    const user: User | null = store.getters["account/user"];

    if (minimumExpectedRole != null && (user?.roleId ?? 0) >= minimumExpectedRole) {
        next();
    }
    else {
        const expectedRole = minimumExpectedRole === Roles.GlobalAdministrator
            ? "a global administrator"
            : "an administrator";
        next({ name: RouteNames.unauthorized, params: { errorCode: `You are not ${expectedRole}.` } });
    }
};
