const baseRoute = "/hubs";

export const HubConstants = {
    get publication() {
        return {
            hubRoute: `${baseRoute}/publication`,
            methods: {
                getLatestFunctionRun: "GetLatestFunctionRun",
                getLatestReleaseRun: "GetLatestReleaseRun",
                onFunctionRunChange: "OnFunctionRunChange",
                onReleaseRunChange: "OnReleaseRunChange",
                requestRelease: "RequestRelease",
                startFunction: "StartFunction",
            },
        };
    },
};
