import { vueAppInsights, vuetify } from "@/plugins";
import { router } from "@/router";
import "@/router/registerHooks";
import { store } from "@/store";
import Vue from "vue";
import "vue-class-component/hooks";
import VueMeta from "vue-meta";
import VueWait from "vue-wait";
import App from "./App.vue";
import { VueDateFilter, VueDaysAgoFilter, VueNumberFormatFilter } from "./filters";
import "./plugins/notifications";
import "./plugins/vee-validate";

Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV !== "production";

// Setup App Insights
vueAppInsights();

Vue.use(VueWait);
Vue.use(VueMeta);

Vue.filter("dateFns", VueDateFilter);
Vue.filter("daysAgo", VueDaysAgoFilter);
Vue.filter("numberWithSpaces", VueNumberFormatFilter);

new Vue({
    render: h => h(App),
    router,
    store,
    vuetify,
    wait: new VueWait({ useVuex: true }),
}).$mount("#app");

// Allow to clear session storage on refresh
sessionStorage.clear();
