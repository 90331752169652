import { DisplayBlockTypes, IDisplayBlockType } from "@/models";

export const DisplayBlockConstants = {
    get aboutUsType(): IDisplayBlockType {
        return {
            code: "AboutUs",
            displayBlockTypeId: DisplayBlockTypes.AboutUs,
        };
    },
    get alcoholHealthType(): IDisplayBlockType {
        return {
            code: "AlcoholHealth",
            displayBlockTypeId: DisplayBlockTypes.AlcoholHealth,
        };
    },
    get displayBlockTypes(): IDisplayBlockType[] {
        return [
            this.nutritionFactsType,
            this.ingredientsType,
            this.responsibleDrinkingType,
            this.alcoholHealthType,
            this.environmentalSustainabilityType,
            this.aboutUsType,
        ];
    },
    get environmentalSustainabilityType(): IDisplayBlockType {
        return {
            code: "EnvironmentalSustainability",
            displayBlockTypeId: DisplayBlockTypes.EnvironmentalSustainability,
        };
    },
    get ingredientsType(): IDisplayBlockType {
        return {
            code: "Ingredients",
            displayBlockTypeId: DisplayBlockTypes.Ingredients,
        };
    },
    get nutritionFactsType(): IDisplayBlockType {
        return {
            code: "NutritionFacts",
            displayBlockTypeId: DisplayBlockTypes.NutritionFacts,
        };
    },
    get responsibleDrinkingType(): IDisplayBlockType {
        return {
            code: "ResponsibleDrinking",
            displayBlockTypeId: DisplayBlockTypes.ResponsibleDrinking,
        };
    },
};
