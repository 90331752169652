const downloadFromBlob = (blob: Blob, fileName: string) => {
    const aElement = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    aElement.href = url;
    aElement.download = fileName;
    aElement.click();
    aElement.remove();
};

export { downloadFromBlob };

