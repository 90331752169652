import { AffiliateGet, BrandGet, CountryGet, LanguageGet } from "@/repositories";
import { MutationTree } from "vuex";
import * as mutationTypes from "./mutations-types";
import { IAppState } from "./state";

export const mutations: MutationTree<IAppState> = {
    [mutationTypes.SET_AFFILIATES]: (state, payload: AffiliateGet[]) => {
        state.affiliates = payload;
    },
    [mutationTypes.SET_AFFILIATES_PROMISE]: (state, payload: Promise<void> | null) => {
        state.affiliatesPromise = payload;
    },
    [mutationTypes.SET_BRANDS]: (state, payload: BrandGet[]) => {
        state.brands = payload;
    },
    [mutationTypes.SET_BRANDS_PROMISE]: (state, payload: Promise<void> | null) => {
        state.brandsPromise = payload;
    },
    [mutationTypes.SET_COUNTRIES]: (state, payload: CountryGet[]) => {
        state.countries = payload;
    },
    [mutationTypes.SET_COUNTRIES_PROMISE]: (state, payload: Promise<void> | null) => {
        state.countriesPromise = payload;
    },
    [mutationTypes.SET_LANGUAGES]: (state, payload: LanguageGet[]) => {
        state.languages = payload;
    },
    [mutationTypes.SET_LANGUAGES_PROMISE]: (state, payload: Promise<void> | null) => {
        state.languagesPromise = payload;
    },
    [mutationTypes.SET_REGIONS]: (state, payload: CountryGet[]) => {
        state.regions = payload;
    },
    [mutationTypes.SET_REGIONS_PROMISE]: (state, payload: Promise<void> | null) => {
        state.regionsPromise = payload;
    },
    [mutationTypes.SET_SHOW_SIDEBAR]: (state, payload: boolean) => {
        state.showSidebar = payload;
    },
    [mutationTypes.SET_TITLE]: (state, payload: string) => {
        state.title = payload;
    },
    [mutationTypes.UPDATE_AFFILIATE]: (state, payload: AffiliateGet) => {
        const affiliateIndex = state.affiliates.findIndex(affiliate => affiliate.affiliateId === payload.affiliateId);
        if (affiliateIndex !== -1) {
            state.affiliates[affiliateIndex] = { ...payload };
            state.affiliates = [...state.affiliates];
        }
    },
    [mutationTypes.UPDATE_BRAND]: (state, payload: BrandGet) => {
        const brandIndex = state.brands.findIndex(brand => brand.brandId === payload.brandId);
        if (brandIndex !== -1) {
            state.brands[brandIndex] = { ...payload };
            state.brands = [...state.brands];
        }
    },
    [mutationTypes.UPDATE_COUNTRY]: (state, payload: CountryGet) => {
        const countryIndex = state.countries.findIndex(country => country.countryId === payload.countryId);
        if (countryIndex !== -1) {
            state.countries[countryIndex] = { ...payload };
            state.countries = [...state.countries];
        }
    },
    [mutationTypes.UPDATE_LANGUAGE]: (state, payload: LanguageGet) => {
        const languageIndex = state.languages.findIndex(language => language.languageId === payload.languageId);
        if (languageIndex !== -1) {
            state.languages[languageIndex] = { ...payload };
            state.languages = [...state.languages];
        }
    },
    [mutationTypes.UPDATE_REGION]: (state, payload: CountryGet) => {
        const regionIndex = state.regions.findIndex(region => region.countryId === payload.countryId);
        if (regionIndex !== -1) {
            state.regions[regionIndex] = { ...payload };
            state.regions = [...state.regions];
        }
    },
};
