import Vue from "vue";

type NotificationTypes = "error" | "info" | "success" | "warning";

export class NotificationService {
    // Properties

    #duration: number;

    // Constructor

    constructor(duration: number) {
        this.#duration = duration;
    }

    // Methods

    cleanNotifications(group?: string) {
        Vue.notify({ clean: true, group });
    }

    showCustomNotification(notificationOptions: NotificationOptions) {
        Vue.notify(notificationOptions);
    }

    showErrorNotification(message: string, group?: string): void {
        this.notify("error", message, group);
    }

    showInformativeNotification(message: string, group?: string): void {
        this.notify("info", message, group);
    }

    showNotification(type: NotificationTypes, message: string, group?: string): void {
        this.notify(type, message, group);
    }

    showSuccessNotification(message: string, group?: string): void {
        this.notify("success", message, group);
    }

    showWarningNotification(message: string, group?: string): void {
        this.notify("warning", message, group);
    }

    // Private methods

    private notify(type: string, message: string, group?: string, duration = this.#duration) {
        Vue.notify({ duration, group, text: message, type });
    }
}

const notificationService = new NotificationService(5000);

export { notificationService };

