import { BlobReader, BlobWriter, ZipWriter } from "@zip.js/zip.js";

export const zipFiles = async (files: IBlobFile[]): Promise<Blob> => {
    // Use a BlobWriter to store with a ZipWriter the zip into a Blob object
    const blobWriter = new BlobWriter("application/zip");
    const zipWriter = new ZipWriter(blobWriter);

    // Use a BlobReader to read the Blob to add
    for (const file of files) {
        const blobReader = new BlobReader(file.data);
        await zipWriter.add(file.fileName, blobReader);
    }

    // Close the ZipWriter
    await zipWriter.close();

    // Get the zip file as a Blob
    return blobWriter.getData();
};

interface IBlobFile {
    data: Blob;
    fileName: string;
}
