import { User } from "@/models";
import * as msal from "@azure/msal-browser";

interface IAccountState {
    msalAccount: msal.AccountInfo | null;
    user: User | null;
    userPromise: Promise<void> | null;
    userPhoto: string;
}

const initialState: IAccountState = {
    msalAccount: null,
    user: null,
    userPhoto: "",
    userPromise: null,
};

export { IAccountState, initialState };
