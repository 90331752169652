import { IDomPurifyConfiguration } from "@/constants";
import DOMPurify from "dompurify";

const buildConfiguration = (domPurifyConfiguration?: IDomPurifyConfiguration): DOMPurify.Config | null => {
    if (domPurifyConfiguration == null) {
        return null;
    }

    const configuration = {} as DOMPurify.Config;

    if (domPurifyConfiguration.allowedAttributes != null) {
        configuration.ALLOWED_ATTR = domPurifyConfiguration.allowedAttributes;
    }
    if (domPurifyConfiguration.allowedTags != null) {
        configuration.ALLOWED_TAGS = domPurifyConfiguration.allowedTags;
    }
    if (domPurifyConfiguration.keepContent != null) {
        configuration.KEEP_CONTENT = domPurifyConfiguration.keepContent;
    }
    return configuration;
};

export const sanitize = (source: string, domPurifyConfiguration?: IDomPurifyConfiguration | undefined): string => {
    const configuration = buildConfiguration(domPurifyConfiguration);

    return configuration != null
        ? DOMPurify.sanitize(source, configuration) as string
        : DOMPurify.sanitize(source);
};
