import { WaitKeys } from "@/helpers";
import {
    AffiliateGet, affiliateRepository,
    BrandGet, brandRepository,
    CountryCompleteGet, CountryGet,
    countryRepository, LanguageGet, languageRepository,
} from "@/repositories";
import { ActionTree } from "vuex";
import {
    SET_AFFILIATES, SET_AFFILIATES_PROMISE, SET_BRANDS, SET_BRANDS_PROMISE,
    SET_COUNTRIES, SET_COUNTRIES_PROMISE, SET_LANGUAGES, SET_LANGUAGES_PROMISE,
    SET_REGIONS,
    SET_REGIONS_PROMISE,
    SET_SHOW_SIDEBAR, SET_TITLE, UPDATE_AFFILIATE, UPDATE_BRAND, UPDATE_COUNTRY, UPDATE_LANGUAGE, UPDATE_REGION,
} from "./mutations-types";
import { IAppState } from "./state";
export const actions: ActionTree<IAppState, any> = {
    loadAffiliates: async (context): Promise<void> => {
        // If there was an API call, wait for the result
        if (context.state.affiliatesPromise !== null) {
            return context.state.affiliatesPromise;
        }

        context.dispatch("wait/start", WaitKeys.affiliatesData, { root: true });
        try {
            const affiliatesPromise = affiliateRepository.getAffiliates();
            context.commit(SET_AFFILIATES_PROMISE, affiliatesPromise);
            const affiliates = await affiliatesPromise;
            context.commit(SET_AFFILIATES, affiliates);
        }
        finally {
            context.commit(SET_AFFILIATES_PROMISE, null);
            context.dispatch("wait/end", WaitKeys.affiliatesData, { root: true });
        }
    },
    loadBrands: async (context): Promise<void> => {
        // If there was an API call, wait for the result
        if (context.state.brandsPromise !== null) {
            return context.state.brandsPromise;
        }

        context.dispatch("wait/start", WaitKeys.brandsData, { root: true });
        try {
            const brandsPromise = brandRepository.getBrands();
            context.commit(SET_BRANDS_PROMISE, brandsPromise);
            const brands = await brandsPromise;
            context.commit(SET_BRANDS, brands);
        }
        finally {
            context.commit(SET_BRANDS_PROMISE, null);
            context.dispatch("wait/end", WaitKeys.brandsData, { root: true });
        }
    },
    loadCountries: async (context): Promise<void> => {
        // If there was an API call, wait for the result
        if (context.state.countriesPromise !== null) {
            return context.state.countriesPromise;
        }

        context.dispatch("wait/start", WaitKeys.countriesData, { root: true });
        try {
            const countriesPromise = countryRepository.getCountries();
            context.commit(SET_COUNTRIES_PROMISE, countriesPromise);
            const countries = await countriesPromise;
            context.commit(SET_COUNTRIES, countries);
        }
        finally {
            context.commit(SET_COUNTRIES_PROMISE, null);
            context.dispatch("wait/end", WaitKeys.countriesData, { root: true });
        }
    },
    loadLanguages: async (context): Promise<void> => {
        // If there was an API call, wait for the result
        if (context.state.languagesPromise !== null) {
            return context.state.languagesPromise;
        }

        context.dispatch("wait/start", WaitKeys.languagesData, { root: true });
        try {
            const languagesPromise = languageRepository.getLanguages();
            context.commit(SET_LANGUAGES_PROMISE, languagesPromise);
            const languages = await languagesPromise;
            context.commit(SET_LANGUAGES, languages);
        }
        finally {
            context.commit(SET_LANGUAGES_PROMISE, null);
            context.dispatch("wait/end", WaitKeys.languagesData, { root: true });
        }
    },
    loadLocations: async (context): Promise<void> => {
        await Promise.all([
            context.dispatch("loadCountries"),
            context.dispatch("loadRegions"),
            context.dispatch("loadLanguages"),
        ]);
    },
    loadOrganizations: async (context): Promise<void> => {
        await Promise.all([
            context.dispatch("loadAffiliates"),
            context.dispatch("loadBrands"),
        ]);
    },
    loadRegions: async (context): Promise<void> => {
        // If there was an API call, wait for the result
        if (context.state.regionsPromise !== null) {
            return context.state.regionsPromise;
        }

        context.dispatch("wait/start", WaitKeys.regionsData, { root: true });
        try {
            const regionsPromise = countryRepository.getRegions();
            context.commit(SET_REGIONS_PROMISE, regionsPromise);
            const regions = await regionsPromise;
            context.commit(SET_REGIONS, regions);
        }
        finally {
            context.commit(SET_REGIONS_PROMISE, null);
            context.dispatch("wait/end", WaitKeys.regionsData, { root: true });
        }
    },
    updateAffiliate: async (context, payload: AffiliateGet): Promise<void> => {
        const updatedAffiliate = await affiliateRepository.updateAffiliate(payload.affiliateId, { websiteUrl: payload.websiteUrl });
        context.commit(UPDATE_AFFILIATE, updatedAffiliate);
    },
    updateBrand: async (context, payload: BrandGet): Promise<void> => {
        const updatedBrand = await brandRepository.updateBrand(payload.brandId, { websiteUrl: payload.websiteUrl });
        context.commit(UPDATE_BRAND, updatedBrand);
    },
    updateCountry: async (context, payload: CountryCompleteGet): Promise<void> => {
        const updatedCompleteCountry = await countryRepository.updateCountry(payload.countryId,
            {
                ageGateEnabled: payload.ageGateEnabled,
                ageGateRestriction: payload.ageGateRestriction,
                displayBlockRestrictions: payload.displayBlockRestrictions,
                isDisplayingBrands: payload.isDisplayingBrands,
                isForbidden: payload.isForbidden,
                isMultilingual: payload.isMultilingual,
                languages: payload.languages,
                nutritionPropertyVisibilities: payload.nutritionPropertiesVisibilities,
                pernodRicardWebsiteLabel: payload.pernodRicardWebsiteLabel,
                pernodRicardWebsiteLink: payload.pernodRicardWebsiteLink,
                pictograms: payload.pictograms,
                responsibleDrinkingNoLowWebsiteLabel: payload.responsibleDrinkingNoLowWebsiteLabel,
                responsibleDrinkingNoLowWebsiteLink: payload.responsibleDrinkingNoLowWebsiteLink,
                responsibleDrinkingSpiritWebsiteLabel: payload.responsibleDrinkingSpiritWebsiteLabel,
                responsibleDrinkingSpiritWebsiteLink: payload.responsibleDrinkingSpiritWebsiteLink,
                responsibleDrinkingWineWebsiteLabel: payload.responsibleDrinkingWineWebsiteLabel,
                responsibleDrinkingWineWebsiteLink: payload.responsibleDrinkingWineWebsiteLink,
                standardDrinkUnit: payload.standardDrinkUnit,
            });
        const updatedCountry: CountryGet = {
            code: updatedCompleteCountry.code,
            countryId: updatedCompleteCountry.countryId,
            isMultilingual: updatedCompleteCountry.isMultilingual,
            languages: updatedCompleteCountry.languages,
            nutritionFactsTemplateId: updatedCompleteCountry.nutritionFactsTemplate?.nutritionFactsTemplateId,
            parentId: updatedCompleteCountry.parentId,
        };
        context.commit(UPDATE_COUNTRY, updatedCountry);
    },
    updateLanguage: async (context, payload: LanguageGet): Promise<void> => {
        const updatedLanguage = await languageRepository.updateLanguage(payload.languageId, { languageLabels: payload.languageLabels });
        context.commit(UPDATE_LANGUAGE, updatedLanguage);
    },
    updateRegion: async (context, payload: CountryCompleteGet): Promise<void> => {
        const updatedCompleteRegion = await countryRepository.updateRegion(payload.countryId,
            {
                ageGateEnabled: payload.ageGateEnabled,
                ageGateRestriction: payload.ageGateRestriction,
                displayBlockRestrictions: payload.displayBlockRestrictions,
                isDisplayingBrands: payload.isDisplayingBrands,
                isForbidden: payload.isForbidden,
                isMultilingual: payload.isMultilingual,
                languages: payload.languages,
                nutritionPropertyVisibilities: payload.nutritionPropertiesVisibilities,
                pernodRicardWebsiteLabel: payload.pernodRicardWebsiteLabel,
                pernodRicardWebsiteLink: payload.pernodRicardWebsiteLink,
                pictograms: payload.pictograms,
                responsibleDrinkingNoLowWebsiteLabel: payload.responsibleDrinkingNoLowWebsiteLabel,
                responsibleDrinkingNoLowWebsiteLink: payload.responsibleDrinkingNoLowWebsiteLink,
                responsibleDrinkingSpiritWebsiteLabel: payload.responsibleDrinkingSpiritWebsiteLabel,
                responsibleDrinkingSpiritWebsiteLink: payload.responsibleDrinkingSpiritWebsiteLink,
                responsibleDrinkingWineWebsiteLabel: payload.responsibleDrinkingWineWebsiteLabel,
                responsibleDrinkingWineWebsiteLink: payload.responsibleDrinkingWineWebsiteLink,
                standardDrinkUnit: payload.standardDrinkUnit,
            });
        const updatedRegion: CountryGet = {
            code: updatedCompleteRegion.code,
            countryId: updatedCompleteRegion.countryId,
            isMultilingual: updatedCompleteRegion.isMultilingual,
            languages: updatedCompleteRegion.languages,
            nutritionFactsTemplateId: updatedCompleteRegion.nutritionFactsTemplate?.nutritionFactsTemplateId,
            parentId: updatedCompleteRegion.parentId,
        };
        context.commit(UPDATE_REGION, updatedRegion);
    },
    updateShowSidebar: async (context, payload: boolean): Promise<void> => {
        context.commit(SET_SHOW_SIDEBAR, payload);
    },
    updateTitle: async (context, payload: string): Promise<void> => {
        context.commit(SET_TITLE, payload);
    },
};
