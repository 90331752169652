import { User } from "@/models";
import * as msal from "@azure/msal-browser";
import { MutationTree } from "vuex";
import * as mutationTypes from "./mutations-types";
import { IAccountState } from "./state";

export const mutations: MutationTree<IAccountState> = {
    [mutationTypes.LOAD_ACCOUNT]: (state, payload: msal.AccountInfo) => {
        state.msalAccount = payload;
    },
    [mutationTypes.LOAD_USER]: (state, payload: User) => {
        state.user = payload;
    },
    [mutationTypes.LOGOUT]: (state) => {
        state.msalAccount = null;
        state.user = null;
    },
    [mutationTypes.SET_PHOTO]: (state, payload: string) => {
        state.userPhoto = payload;
    },
    [mutationTypes.SET_USER_PROMISE]: (state, payload: Promise<void> | null) => {
        state.userPromise = payload;
    },
};
