import { GlobalMessageTypes, IGlobalMessageType } from "@/models";

export const GlobalMessageConstants = {
    get alcoholHealthType(): IGlobalMessageType {
        return {
            code: "AlcoholHealth",
            globalMessageTypeId: GlobalMessageTypes.AlcoholHealth,
        };
    },
    get globalMessageTypes(): IGlobalMessageType[] {
        return [
            this.responsibleDrinkingType,
            this.alcoholHealthType,
            this.sustainabilityType,
        ];
    },
    get responsibleDrinkingType(): IGlobalMessageType {
        return {
            code: "ResponsibleDrinking",
            globalMessageTypeId: GlobalMessageTypes.ResponsibleDrinking,
        };
    },
    get sustainabilityType(): IGlobalMessageType {
        return {
            code: "Sustainability",
            globalMessageTypeId: GlobalMessageTypes.Sustainability,
        };
    },
};
