const SET_AFFILIATES = "SET_AFFILIATES";
const SET_AFFILIATES_PROMISE = "SET_AFFILIATES_PROMISE";
const SET_BRANDS = "SET_BRANDS";
const SET_BRANDS_PROMISE = "SET_BRANDS_PROMISE";
const SET_COUNTRIES = "SET_COUNTRIES";
const SET_COUNTRIES_PROMISE = "SET_COUNTRIES_PROMISE";
const SET_LANGUAGES = "SET_LANGUAGES";
const SET_LANGUAGES_PROMISE = "SET_LANGUAGES_PROMISE";
const SET_REGIONS = "SET_REGIONS";
const SET_REGIONS_PROMISE = "SET_REGIONS_PROMISE";
const SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR";
const SET_TITLE = "SET_TITLE";
const UPDATE_AFFILIATE = "UPDATE_AFFILIATE";
const UPDATE_BRAND = "UPDATE_BRAND";
const UPDATE_COUNTRY = "UPDATE_COUNTRY";
const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
const UPDATE_REGION = "UPDATE_REGION";

export {
    SET_AFFILIATES,
    SET_AFFILIATES_PROMISE,
    SET_BRANDS,
    SET_BRANDS_PROMISE,
    SET_COUNTRIES,
    SET_COUNTRIES_PROMISE,
    SET_LANGUAGES,
    SET_LANGUAGES_PROMISE,
    SET_REGIONS,
    SET_REGIONS_PROMISE,
    SET_SHOW_SIDEBAR,
    SET_TITLE,
    UPDATE_AFFILIATE,
    UPDATE_BRAND,
    UPDATE_COUNTRY,
    UPDATE_LANGUAGE,
    UPDATE_REGION,
};
