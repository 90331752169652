import {
    extend,
    localize, ValidationObserver,
    ValidationProvider,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, {
        ...validation,
    });
}

// Web URL

extend("web_url", {
    message: field => `The ${field} must be a valid URL.`,
    validate: (value: any) => !value || (value && /^([hH][tT]{2}[pP][sS]?:\/\/)(w{3}\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)), // NOSONAR
});

localize({
    en,
});
localize("en");
