import { IRole, Roles } from "@/models";

export const RoleConstants = {
    get administratorRole(): IRole {
        return {
            label: "Administrator",
            roleId: Roles.Administrator,
        };
    },
    get globalAdministratorRole(): IRole {
        return {
            label: "Global administrator",
            roleId: Roles.GlobalAdministrator,
        };
    },
    get roles(): IRole[] {
        return [
            this.userRole,
            this.administratorRole,
            this.globalAdministratorRole,
        ];
    },
    get userRole(): IRole {
        return {
            label: "User",
            roleId: Roles.User,
        };
    },
};
