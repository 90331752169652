export enum WordingIdentifiers {
    PrivacyPolicy = 1,
    TermsAndConditions = 2,
    Next = 3,
    PortionPrefix = 4,
    PortionSuffix = 5,
    StandardDrinkLabel = 6,
    AlcoholLabel = 7,
    ProductLabel = 8,
    AlcoholVolumeLabel = 9,
    IngredientMention = 10,
    ResponsibleDrinkingAdditionalInformation = 11,
    AgeGateMessage = 12,
    AgeGateButtonCancel = 13,
    AgeGateButtonValidate = 14,
    ResponsibleDrinkingPolicyLabel = 15,
    ResponsibleDrinkingPolicyUrl = 16,
}
