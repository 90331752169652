import { IWording, WordingIdentifiers } from "@/models";

export const WordingConstants = {
    get ageGateButtonCancel(): IWording {
        return {
            wording: "Age Gate button cancel",
            wordingId: WordingIdentifiers.AgeGateButtonCancel,
        };
    },
    get ageGateButtonValidate(): IWording {
        return {
            wording: "Age Gate button validate",
            wordingId: WordingIdentifiers.AgeGateButtonValidate,
        };
    },
    get ageGateMessage(): IWording {
        return {
            wording: "Age Gate Message",
            wordingId: WordingIdentifiers.AgeGateMessage,
        };
    },
    get alcoholLabel(): IWording {
        return {
            wording: "AlcoholLabel",
            wordingId: WordingIdentifiers.AlcoholLabel,
        };
    },
    get alcoholVolumeLabel(): IWording {
        return {
            wording: "AlcoholVolumeLabel",
            wordingId: WordingIdentifiers.AlcoholVolumeLabel,
        };
    },
    get ingredientMention(): IWording {
        return {
            wording: "Mention",
            wordingId: WordingIdentifiers.IngredientMention,
        };
    },
    get next(): IWording {
        return {
            wording: "Next",
            wordingId: WordingIdentifiers.Next,
        };
    },
    get portionPrefix(): IWording {
        return {
            wording: "PortionPrefix",
            wordingId: WordingIdentifiers.PortionPrefix,
        };
    },
    get portionSuffix(): IWording {
        return {
            wording: "PortionSuffix",
            wordingId: WordingIdentifiers.PortionSuffix,
        };
    },
    get privacyPolicy(): IWording {
        return {
            wording: "PrivacyPolicy",
            wordingId: WordingIdentifiers.PrivacyPolicy,
        };
    },
    get productLabel(): IWording {
        return {
            wording: "ProductLabel",
            wordingId: WordingIdentifiers.ProductLabel,
        };
    },
    get responsibleDrinkingAdditionalInformation(): IWording {
        return {
            wording: "ResponsibleDrinkingAdditionalInformation",
            wordingId: WordingIdentifiers.ResponsibleDrinkingAdditionalInformation,
        };
    },
    get responsibleDrinkingPolicyLabel(): IWording {
        return {
            wording: "ResponsibleDrinkingPolicyLabel",
            wordingId: WordingIdentifiers.ResponsibleDrinkingPolicyLabel,
        };
    },
    get responsibleDrinkingPolicyUrl(): IWording {
        return {
            wording: "ResponsibleDrinkingPolicyUrl",
            wordingId: WordingIdentifiers.ResponsibleDrinkingPolicyUrl,
        };
    },
    get standardDrinkLabel(): IWording {
        return {
            wording: "StandardDrinkLabel",
            wordingId: WordingIdentifiers.StandardDrinkLabel,
        };
    },
    get termsAndConditions(): IWording {
        return {
            wording: "TermsAndConditions",
            wordingId: WordingIdentifiers.TermsAndConditions,
        };
    },
    get wordings(): IWording[] {
        return [
            this.privacyPolicy,
            this.termsAndConditions,
            this.portionPrefix,
            this.portionSuffix,
            this.standardDrinkLabel,
            this.alcoholLabel,
            this.productLabel,
            this.ingredientMention,
            this.ageGateMessage,
            this.ageGateButtonCancel,
            this.ageGateButtonValidate,
            this.responsibleDrinkingPolicyLabel,
            this.responsibleDrinkingPolicyUrl,
        ];
    },
};
