const formatter = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

export const VueDaysAgoFilter = (value: string | Date): string => {
    if (!value) {
        return "";
    }

    const valueDate = value instanceof Date
        ? value
        : new Date(value);

    const timeDifference = new Date().getTime() - valueDate.getTime();
    const timeDaysRatio = 1000 * 60 * 60 * 24;

    return formatter.format(Math.ceil(-timeDifference / timeDaysRatio), "day");
};
