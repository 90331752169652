export const settings = {
    appInsights: {
        instrumentationKey: process.env.VUE_APP_APP_INSIGHTS_INSTRUMENTATION_KEY as string,
    },
    appName: process.env.VUE_APP_NAME as string,
    azureAd: {
        callbackPath: process.env.VUE_APP_AZURE_AD_CALLBACKPATH as string,
        clientId: process.env.VUE_APP_AZURE_AD_CLIENTID as string,
        instance: process.env.VUE_APP_AZURE_AD_INSTANCE as string,
        scopes: process.env.VUE_APP_AZURE_AD_SCOPES as string,
        tenantId: process.env.VUE_APP_AZURE_AD_TENANTID as string,
    },
    environment: process.env.VUE_APP_ENV as "Development" | "Staging" | "Production",
    productCloud: {
        assetsBaseUrl: process.env.VUE_APP_PRODUCT_CLOUD_ASSETS_BASE_URL as string,
    },
    storage: {
        containerUrl: process.env.VUE_APP_AZURE_STORAGE_CONTAINER as string,
    },
    webApi: {
        baseUrl: process.env.VUE_APP_WEB_API_BASE_URL as string,
        version: process.env.VUE_APP_WEB_API_VERSION as string,
    },
    webEndUser: {
        chinaBaseUrl: process.env.VUE_APP_WEB_ENDUSER_CHINA_BASE_URL as string,
        worldBaseUrl: process.env.VUE_APP_WEB_ENDUSER_WORLD_BASE_URL as string,
    },
};
