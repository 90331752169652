import { router } from "@/router";
import { settings } from "@/settings";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Vue from "vue";
import VueAppInsights from "vue-application-insights";

export const vueAppInsights = (): void => {
    // Setup Application Insights
    if (settings.appInsights.instrumentationKey) {
        const appInsights = new ApplicationInsights({
            config: {
                disableCookiesUsage: true,
                enableCorsCorrelation: true,
                instrumentationKey: settings.appInsights.instrumentationKey,
            },
        });

        appInsights.loadAppInsights();
        appInsights.trackPageView();

        Vue.use(VueAppInsights, {
            appInsights,
            id: settings.appInsights.instrumentationKey,
            router,
            trackInitialPageView: false,
        });

        // Log all errors
        Vue.config.errorHandler = (err: Error) => {
            appInsights.trackException({ exception: err });
            throw err;
        };
    }
};
