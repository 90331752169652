export class RouteNames {
    static readonly home = "home";

    static readonly liquidQualities = "liquid-qualities";

    static readonly affiliatesAdministration = "affiliates";

    static readonly brandsAdministration = "brands";

    static readonly countriesAdministration = "countries";

    static readonly genericCountryAdministration = "genericCountry";

    static readonly countryAdministration = "country";

    static readonly languagesAdministration = "languages";

    static readonly publicationAdministration = "publication";

    static readonly regionsAdministration = "regions";

    static readonly regionAdministration = "region";

    static readonly usersAdministration = "users";

    static readonly unauthorized = "unauthorized";

    static readonly notFound = "not-found";
}
