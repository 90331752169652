import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export const vuetify = new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                accent: "#763900",
                primary: "#003c76",
                secondary: "#000276",
            },
        },
    },
});
