import { IRunExecutionRequester } from "./IRunExecutionRequester";
import { RunExecutionStatus } from "./RunExecutionStatus";
import { RunExecutionTypes } from "./RunExecutionTypes";

export interface IRunExecution {
    RunExecutionId: number;
    RunExecutionType: RunExecutionTypes;
    RunExecutionStatus: RunExecutionStatus;
    RequestedBy: IRunExecutionRequester;
    RequestedDate: string;
    ExecutedDate: string;
}
