import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { adminRoutes } from "./AdminRoutes";
import { authGuard } from "./AuthGuard";
import { roleGuard } from "./RoleGuard";
import { RouteNames } from "./RouteNames";

Vue.use(VueRouter);

const allRoutes: RouteConfig[] = [
    {
        children: [
            {
                component: () => import(/* webpackChunkName: "home" */ "../views/home/Home.vue"),
                name: RouteNames.home,
                path: "/",
            },
            {
                component: () => import(/* webpackChunkName: "liquid-quality" */ "../views/liquid-qualities/LiquidQuality.vue"),
                name: RouteNames.liquidQualities,
                path: "/liquid-qualities/:code",
            },
            {
                component: () => import(/* webpackChunkName: "unauthorized" */ "@/views/unauthorized/Unauthorized.vue"),
                name: RouteNames.unauthorized,
                path: "/unauthorized",
            },
            ...adminRoutes,
            {
                component: () => import(/* webpackChunkName: "not-found" */ "@/views/not-found/NotFound.vue"),
                name: RouteNames.notFound,
                path: "*",
            },
        ],
        component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        path: "/",
    },
    {
        path: "/auth",
        redirect: "/",
    },
];

const router = new VueRouter({
    mode: "history",
    routes: allRoutes,
    scrollBehavior: (to) => {
        if (to.hash) {
            return {
                behavior: "smooth",
                selector: to.hash,
            };
        }
    },
});

router.beforeEach(authGuard);
router.beforeEach(roleGuard);

export { router, RouteNames };

