import { RoleConstants } from "@/constants";
import { Roles } from "@/models";

export const getRoleLabel = (roleId: Roles): string => {
    switch (roleId) {
        case Roles.User: return RoleConstants.userRole.label;
        case Roles.Administrator: return RoleConstants.administratorRole.label;
        case Roles.GlobalAdministrator: return RoleConstants.globalAdministratorRole.label;
        default: return "";
    }
};
