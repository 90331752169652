import format from "date-fns/format";

export const VueDateFilter = (value: string | Date): string => {
    if (!value) {
        return "";
    }

    const date = value instanceof Date
        ? value
        : new Date(value);

    return format(date, "dd/MM/yyyy");
};
