import { BrandGet, UserGet } from "@/repositories";
import { Roles } from "../api/Roles";

export class User implements UserGet {
    // Properties

    userId: number;

    uniqueId: string;

    email: string;

    name: string;

    roleId: Roles;

    brandIds: number[] | null;

    isActive: boolean;

    // Constructor

    constructor(user: UserGet) {
        this.userId = user.userId;
        this.uniqueId = user.uniqueId;
        this.email = user.email;
        this.name = user.name;
        this.roleId = user.roleId;
        this.brandIds = user.brandIds;
        this.isActive = user.isActive;
    }

    isAllowedForBrandAccess(brand: BrandGet | number) {
        const brandId = typeof (brand) === "number" ? brand : brand.brandId;
        return this.roleId === Roles.GlobalAdministrator || this.brandIds.includes(brandId);
    }

    isAllowedForBrandAdministration(brand: BrandGet | number) {
        const brandId = typeof (brand) === "number" ? brand : brand.brandId;
        return this.roleId === Roles.GlobalAdministrator || (this.roleId === Roles.Administrator && this.brandIds.includes(brandId));
    }

    isMinAdministrator() {
        return this.roleId >= Roles.Administrator;
    }

    isGlobalAdministrator() {
        return this.roleId === Roles.GlobalAdministrator;
    }
}
