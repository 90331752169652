export enum NutritionProperties {
    EnergyKCal = 1,
    EnergyKJ = 2,
    Fat = 3,
    SaturatedFat = 4,
    Carbohydrates = 5,
    CarbohydratesSugars = 6,
    Protein = 7,
    Salt = 8,
    Alcohol = 9,
    SodiumMg = 10,
    TransFat = 11,
    Cholesterol = 12,
    DietaryFibers = 13,
    AddedSugars = 14,
}
